import { Box, Typography } from '@mui/material';
import { PaymentMethod as EPaymentMethod, Order } from '../../../order.types';
import CreditCardIcon from '../../../../../assets/icons/credit-card-icon.svg';
import WalletIcon from '../../../../../assets/icons/wallet-icon.svg';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../../../../../constants/language';

const PaymentMethod: React.FC<{ item: Order }> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Box bgcolor="white" padding="12px 16px" display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize="14px" lineHeight="20px" color="#667085" fontWeight="500">
        {t(LANG_TEXT.ORDER_PAYMENT_METHOD)}
      </Typography>
      <Box display="flex" gap="8px" alignItems="center">
        <img src={item?.payment_method === EPaymentMethod.CREDIT_CARD ? CreditCardIcon : WalletIcon} alt="wallet" width={20} height={14} />
        <Typography fontSize="14px" lineHeight="20px" color="#344054" fontWeight="500">
          {item?.payment_method === EPaymentMethod.CREDIT_CARD ? t(LANG_TEXT.CREDIT_CARD) : t(item?.payment_method)}
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentMethod;
