import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';
import firebaseProdConfig from './firebase.production.config.json';
import firebaseStagConfig from './firebase.staging.config.json';

// Initialize Firebase for production
const firebaseConfig = process.env.REACT_APP_ENV === 'staging' ? firebaseStagConfig : firebaseProdConfig;
firebase.initializeApp(firebaseConfig);

export default firebase;
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const messaging = firebase.messaging();
