import { Box, Divider, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BoxIcon from '../../../../assets/icons/box-icon.svg';
import ClockCheckIcon from '../../../../assets/icons/clock-check-icon.svg';
import ClockIcon from '../../../../assets/icons/clock-icon.svg';
import InfoIcon from '../../../../assets/icons/info-circle-icon.svg';
import LocationIcon from '../../../../assets/icons/location-icon.svg';
import SeatIcon from '../../../../assets/icons/seat-icon.svg';
import { CustomButton } from '../../../../components/Button';
import { CustomChip } from '../../../../components/Chip';
import { LANG_TEXT } from '../../../../constants/language';
import { convertText, formatFirebaseTime } from '../../../../utils/common';
import { STATUS_COLOR_MAP } from '../../data';
import { DamagedItemStatus, ICreateOrder, Order, OrderItem, OrderItemStatus } from '../../order.types';
import { useNotificationContext } from '../../../../context/notificationContext';
import { useOrderContext } from '../../../../context/orderContext';
import Modal from '../../../../components/Modal';
import firebase from 'firebase/compat/app';

interface OrderItemsProps {
  item: Order;
  disabled?: boolean;
  onClickCTA?: (item: Order) => void;
  onShowInfo: (item: Order) => void;
}

const OrderItems: React.FC<OrderItemsProps> = ({ item, onClickCTA, onShowInfo, disabled }) => {
  const { t } = useTranslation();
  const { showToast } = useNotificationContext();
  const { handleUpdateOrderStatus, handleCreateOrder } = useOrderContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderButton = useMemo(() => {
    if (!item?.rider_id)
      return (
        <CustomButton
          variant="contained"
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            onClickCTA && onClickCTA(item);
          }}
        >
          {t(LANG_TEXT.PICK_UP_ORDER)}
        </CustomButton>
      );
    switch (item.order_status) {
      case OrderItemStatus.PREPARING: {
        return (
          <CustomButton variant="outlined" disabled startIcon={<img src={ClockIcon} width={17} height={17} alt="user" />}>
            {t(LANG_TEXT.PREPARING)}
          </CustomButton>
        );
      }
      case OrderItemStatus.DAMAGED_PREPARING: {
        return (
          <CustomButton variant="outlined" disabled startIcon={<img src={ClockIcon} width={17} height={17} alt="user" />}>
            {t(LANG_TEXT.PREPARING)}
          </CustomButton>
        );
      }
      case OrderItemStatus.DELIVERING: {
        return (
          <CustomButton variant="outlined" disabled startIcon={<img src={ClockIcon} width={17} height={17} alt="user" />}>
            {t(LANG_TEXT.DELIVERING)}
          </CustomButton>
        );
      }
      case OrderItemStatus.DAMAGED_DELIVERING: {
        return (
          <CustomButton variant="outlined" disabled startIcon={<img src={ClockIcon} width={17} height={17} alt="user" />}>
            {t(LANG_TEXT.DELIVERING)}
          </CustomButton>
        );
      }
      case OrderItemStatus.READY_FOR_PICK_UP: {
        return (
          <CustomButton
            variant="contained"
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              onClickCTA && onClickCTA(item);
            }}
          >
            {t(LANG_TEXT.PICKED_UP_FOOD)}
          </CustomButton>
        );
      }
      case OrderItemStatus.DAMAGED_READY_FOR_PICK_UP: {
        return (
          <CustomButton
            variant="contained"
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              onClickCTA && onClickCTA(item);
            }}
          >
            {t(LANG_TEXT.PICKED_UP_FOOD)}
          </CustomButton>
        );
      }
      default: {
        return null;
      }
    }
  }, [disabled, item]);

  const status = useMemo(() => {
    const statusMap = {
      [OrderItemStatus.PREPARING]: t(LANG_TEXT.PREPARING),
      [OrderItemStatus.DAMAGED_PREPARING]: t(LANG_TEXT.PREPARING),
      [OrderItemStatus.READY_FOR_PICK_UP]: t(LANG_TEXT.READY_FOR_PICK_UP),
      [OrderItemStatus.DAMAGED_READY_FOR_PICK_UP]: t(LANG_TEXT.READY_FOR_PICK_UP),
      [OrderItemStatus.DELIVERING]: t(LANG_TEXT.DELIVERING),
      [OrderItemStatus.DAMAGED_DELIVERING]: t(LANG_TEXT.DELIVERING),
      [OrderItemStatus.DELIVERED]: t(LANG_TEXT.DELIVERED),
    };
    return convertText(item?.rider_id ? statusMap[item.order_status] : t(LANG_TEXT.NEW));
  }, [item]);

  const total = useMemo(() => {
    const totalPromotionFoods =
      item.promotion_foods?.reduce(
        (total: number, food: OrderItem) => total + (food.item_deleted_quantity !== undefined ? food.item_quantity - food.item_deleted_quantity : food.item_quantity),
        0
      ) || 0;
    const totalFoods =
      item.foods?.reduce(
        (total: number, food: OrderItem) => total + (food.item_deleted_quantity !== undefined ? food.item_quantity - food.item_deleted_quantity : food.item_quantity),
        0
      ) || 0;

    return totalPromotionFoods + totalFoods;
  }, [item]);

  const handleDamageFood = async (_order: Order) => {
    if (_order.id) {
      try {
        const newOrderData = updateOrderData(_order);
        const damaged_number = _order?.damaged_number ?? 0;
        const id = damaged_number > 0 ? `${_order.id}_DAMAGED_${_order?.damaged_number}` : `${_order.id}_DAMAGED`;
        await createOrder(id, newOrderData);
        await handleUpdateOrderStatus(_order.id, {
          order_status: OrderItemStatus.DAMAGED_PREPARING,
          has_damaged_order: true,
          damaged_number: damaged_number + 1,
        });

        showToast({
          content: t(LANG_TEXT.PICK_UP_ORDER_SUCCESS_MESSAGE),
          type: 'success',
        });
      } catch (error) {
        showToast({
          content: t(LANG_TEXT.PICK_UP_ORDER_FAIL_MESSAGE),
          type: 'error',
        });
      }
    }
  };

  const updateOrderData = (order: Order): ICreateOrder => {
    return {
      bill_status: order.bill_status || '',
      foods: order.foods.map((food) => {
        const { item_deleted_quantity, ...rest } = food;
        rest.item_quantity = item_deleted_quantity !== undefined ? rest.item_quantity - item_deleted_quantity : rest.item_quantity;
        return rest;
      }),
      order_create_time: firebase.firestore.Timestamp.fromDate(new Date()),
      order_status: DamagedItemStatus.DAMAGED_CREATED,
      order_table_number: order.order_table_number || '',
      ordered_by: order.ordered_by || '',
      payment_method: order.payment_method || '',
      promotion_foods: order.promotion_foods.map((food) => {
        const { item_deleted_quantity, ...rest } = food;
        rest.item_quantity = item_deleted_quantity !== undefined ? rest.item_quantity - item_deleted_quantity : rest.item_quantity;
        return rest;
      }),
      restaurant_id: order.restaurant_id || '',
      rider_id: order.rider_id || undefined,
      seat_number: order.seat_number || '',
      seat_type: order.seat_type || '',
      parent_id: order.id,
      is_damaged: true,
    };
  };

  const createOrder = async (id: string, orderDetails: ICreateOrder) => {
    try {
      await handleCreateOrder(id, orderDetails);
      showToast({
        content: t(LANG_TEXT.PICK_UP_ORDER_SUCCESS_MESSAGE),
        type: 'success',
      });
    } catch (error) {
      showToast({
        content: t(LANG_TEXT.PICK_UP_ORDER_FAIL_MESSAGE),
        type: 'error',
      });
    }
  };

  return (
    <Box
      sx={{ opacity: disabled ? 0.4 : 1 }}
      padding="16px"
      borderBottom="1px solid #EAECF0"
      role="button"
      onClick={() => {
        if (!disabled) onShowInfo(item);
      }}
    >
      <Box display="flex" flexDirection="column" gap="24px">
        <Box display="flex" flexDirection="column" gap="12px">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <CustomChip size="md" bgcolor="#F2F4F7" color="#344054" height="max-content">
              {item.id} {item.has_damaged_order ? ` (${t(LANG_TEXT.DAMAGED)})` : ''}
            </CustomChip>
            <Box display="flex" flexDirection="row" gap="8px">
              {(item.order_status === OrderItemStatus.DELIVERING || item.order_status === OrderItemStatus.DAMAGED_DELIVERING) && (
                <CustomButton
                  variant="contained"
                  padding="0 6px"
                  onClick={async (e) => {
                    handleOpenModal();
                    e.stopPropagation();
                  }}
                >
                  {t(LANG_TEXT.DAMAGED_FOOD)}
                </CustomButton>
              )}
              <CustomChip
                size="md"
                bgcolor={STATUS_COLOR_MAP[item?.rider_id ? item.order_status : OrderItemStatus.PREPARING].bgColor}
                color={STATUS_COLOR_MAP[item?.rider_id ? item.order_status : OrderItemStatus.PREPARING].color}
                textTransform="capitalize"
                height="max-content"
              >
                {status}
              </CustomChip>
            </Box>
          </Box>
          <Typography color="#475467" lineHeight="28px" fontWeight={600} fontSize="18px">
            {item.type === 'promotion' ? item?.restaurant_promotion?.name : item?.restaurant?.name || '-'}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" gap="8px" alignItems="center">
              <img src={LocationIcon} width={24} height={24} alt="user" />
              <Typography color="#475467" lineHeight="24px" fontWeight={500} fontSize="16px">
                {item.type === 'promotion' ? item?.restaurant_promotion?.region : item?.restaurant?.region || '-'} {t(LANG_TEXT.ZONE)}
              </Typography>
            </Box>
            <Divider color="#98A2B3" orientation="vertical" flexItem />
            <Box display="flex" gap="8px" alignItems="center">
              <img src={BoxIcon} width={24} height={24} alt="user" />
              <Typography color="#475467" lineHeight="24px" fontWeight={500} fontSize="16px">
                {total} {t(LANG_TEXT.ORDER_ITEMS)}
              </Typography>
            </Box>
            <Divider color="#98A2B3" orientation="vertical" flexItem />
            <Box display="flex" gap="8px" alignItems="center">
              <img src={SeatIcon} width={24} height={24} alt="user" />
              <Typography color="#475467" lineHeight="24px" fontWeight={500} fontSize="16px">
                {item.seat_number}
              </Typography>
            </Box>
          </Box>
        </Box>
        {renderButton}
      </Box>
      {disabled && (
        <Box display="flex" gap="8px" marginTop="8px">
          <img src={InfoIcon} width={14} height={14} alt="user" />
          <Typography color="#667085" lineHeight="18px" fontWeight={400} fontSize="12px">
            {t(LANG_TEXT.ORDER_DISABLE_TOOLTIP)}
          </Typography>
        </Box>
      )}
      {item.order_status === OrderItemStatus.DELIVERED && (
        <Box display="flex" gap="8px" marginTop="16px">
          <img src={ClockCheckIcon} width={17} height={17} alt="user" />
          <Typography color="#98A2B3" lineHeight="20px" fontWeight={400} fontSize="14px">
            {t(LANG_TEXT.ORDER_DELIVERED_AT, {
              date: formatFirebaseTime(item?.order_delivered_time?.seconds),
              interpolation: { escapeValue: false },
            })}
          </Typography>
        </Box>
      )}
      <Modal open={openModal} handleClose={handleCloseModal} handleSubmit={async () => await handleDamageFood(item)} />
    </Box>
  );
};

export default OrderItems;
