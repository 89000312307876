import { Box, Typography } from '@mui/material';
import React from 'react';
import { OrderItem } from '../../../order.types';
import { LANG_TEXT } from '../../../../../constants/language';
import { useTranslation } from 'react-i18next';

const OrderSummaryItem: React.FC<{ orderItem: OrderItem }> = ({ orderItem }) => {
  const { t } = useTranslation();

  const remainingQuantity = orderItem.item_quantity - (orderItem.item_deleted_quantity || 0);
  const hasDeletedQuantity = orderItem.item_deleted_quantity !== undefined && orderItem.item_deleted_quantity > 0;

  const renderItem = (quantity: number, isDeleted: boolean) => {
    const subtotal = parseFloat((quantity * orderItem.item_price).toFixed(2));

    return (
      <Box
        borderBottom="1px solid #EAECF0"
        padding="16px"
        display="flex"
        justifyContent="space-between"
        gap="12px"
        sx={{ textDecoration: isDeleted ? 'line-through' : 'none' }}
      >
        <Box display="flex" flex="1 1 0%">
          <Box display="flex" gap="4px" flexDirection="column" width="100%">
            <Typography fontSize="14px" lineHeight="20px" color="#1D2939" fontWeight="600">
              {orderItem.item_name}
            </Typography>
            {orderItem.item_customize_settings?.length > 0 && (
              <Box display="flex" gap="8px" flexWrap="wrap" alignItems="start" flexDirection="column">
                {orderItem.item_customize_settings.map((customizeItem, index) => (
                  <Typography key={`${customizeItem.name}-${index}`} fontSize="12px" lineHeight="18px" color="#667085" fontWeight="500">
                    - {customizeItem.name}
                  </Typography>
                ))}
              </Box>
            )}
            {orderItem?.item_note && (
              <>
                <Box borderBottom="1px solid #EAECF0" height="1px" />
                <Typography fontSize="12px" lineHeight="18px" color="#98A2B3" fontWeight="400">
                  {orderItem.item_note}
                </Typography>
              </>
            )}
            <Typography fontSize="14px" lineHeight="20px" color="#1D2939" fontWeight="500" flexShrink={0}>
              {t(LANG_TEXT.QUANTITY)}: {quantity} {t(LANG_TEXT.ORDER_ITEMS)}
            </Typography>
          </Box>
        </Box>

        <Typography fontSize="14px" lineHeight="20px" color="#98A2B3" fontWeight="400">
          ¥ {subtotal.toLocaleString('ja-JP')}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {hasDeletedQuantity && renderItem(orderItem.item_quantity, true)}
      {remainingQuantity >= 0 && renderItem(remainingQuantity, false)}
    </>
  );
};

export default OrderSummaryItem;
