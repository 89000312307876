import { Box } from '@mui/material';
import firebase from 'firebase/compat/app';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomTab, CustomTabs } from '../../components/Tabs';
import TabPanel from '../../components/Tabs/TabPanel';
import { useAuthContext } from '../../context/authContext';
import { useNotificationContext } from '../../context/notificationContext';
import { useOrderContext } from '../../context/orderContext';
import DeliveryInfo from './DeliveryInfo';
import TabContent from './components/TabContent';
import TabLabel from './components/TabLabel';
import { DamagedItemStatus, Order, OrderItemStatus, TabValue } from './order.types';
import { LANG_TEXT } from '../../constants/language';

const Orders = () => {
  const { t } = useTranslation();
  const { user, isOnline } = useAuthContext();
  const { orderList, deliveredOrders, newOrders, ongoingOrders, loading, handleUpdateOrderStatus, updateOrderStatusDamaged } = useOrderContext();
  const { showToast } = useNotificationContext();

  const navigate = useNavigate();

  const searchParams = useSearchParams();
  const orderId = searchParams[0].get('id');
  const init = useRef(false);

  const [tab, setTab] = useState(TabValue.NEW_ORDER);
  const [showInfo, setShowInfo] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>();

  useEffect(() => {
    if (selectedOrder) {
      const selected = orderList?.find((item) => item.id === selectedOrder.id);
      if (selected) {
        setSelectedOrder(selected);
      }
    }
  }, [orderList]);

  useEffect(() => {
    if (orderId && orderList) {
      const selected = orderList?.find((item) => item.id === orderId);
      if (selected) {
        setShowInfo(true);

        setSelectedOrder(selected);
        init.current = true;
      }
    }
  }, [orderId, orderList]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTab(newValue);
  };

  const handleShowInfo = (_order: Order) => {
    setShowInfo(true);
    setSelectedOrder(_order);
  };

  const handleCloseInfo = () => {
    setShowInfo(false);
    navigate(`/`);
  };

  const handlePickup = async (_order: Order) => {
    if (_order.id && user) {
      try {
        await handleUpdateOrderStatus(_order.id, { rider_id: user.uid });

        setTab(TabValue.ONGOING);
        showToast({
          content: t(LANG_TEXT.PICK_UP_ORDER_SUCCESS_MESSAGE),
          type: 'success',
        });
      } catch (error) {
        showToast({
          content: t(LANG_TEXT.PICK_UP_ORDER_FAIL_MESSAGE),
          type: 'error',
        });
      }
    }
  };

  const handleConfirmPickedUp = async (_order: Order) => {
    if (!_order.id || !user) return;

    try {
      const updateData: Record<string, any> = { rider_id: user.uid };
      const newStatus = _order?.order_status === OrderItemStatus.DAMAGED_READY_FOR_PICK_UP ? OrderItemStatus.DAMAGED_DELIVERING : OrderItemStatus.DELIVERING;

      if (_order.foods?.length && _order.type) {
        const isMixedStatus =
          (_order.food_order_status === OrderItemStatus.READY_FOR_PICK_UP && _order.food_promotion_order_status === OrderItemStatus.DELIVERING) ||
          (_order.food_order_status === OrderItemStatus.DAMAGED_READY_FOR_PICK_UP && _order.food_promotion_order_status === OrderItemStatus.DAMAGED_DELIVERING) ||
          (_order.food_order_status === OrderItemStatus.DELIVERING && _order.food_promotion_order_status === OrderItemStatus.READY_FOR_PICK_UP) ||
          (_order.food_order_status === OrderItemStatus.DAMAGED_DELIVERING && _order.food_promotion_order_status === OrderItemStatus.DAMAGED_READY_FOR_PICK_UP);

        if (isMixedStatus) {
          updateData.order_status = newStatus;
        }

        const foodStatusKey = _order.type === 'promotion' ? 'food_promotion_order_status' : 'food_order_status';
        updateData[foodStatusKey] = newStatus;
      } else {
        updateData.order_status = newStatus;
      }

      await handleUpdateOrderStatus(_order.id, updateData);
      if (_order.has_damaged_order) {
        await updateOrderStatusDamaged(_order.id, DamagedItemStatus.DAMAGED_DELIVERING);
      }

      showToast({
        content: t(LANG_TEXT.PICKED_UP_FOOD_SUCCESS_MESSAGE),
        type: 'success',
      });
    } catch (error) {
      showToast({
        content: t(LANG_TEXT.UPDATE_STATUS_FAIL_MESSAGE),
        type: 'error',
      });
    }
  };

  const handleConfirmDelivered = async (_order: Order) => {
    if (!_order.id || !user) return;

    try {
      const newStatus = OrderItemStatus.DELIVERED;
      const timestamp = firebase.firestore.Timestamp.now();
      const riderId = user.uid;

      if (_order.foods?.length && _order.type) {
        const isMixedStatus =
          (_order.food_order_status === OrderItemStatus.DELIVERING && _order.food_promotion_order_status === OrderItemStatus.DELIVERED) ||
          (_order.food_order_status === OrderItemStatus.DAMAGED_DELIVERING && _order.food_promotion_order_status === OrderItemStatus.DELIVERED) ||
          (_order.food_order_status === OrderItemStatus.DELIVERED && _order.food_promotion_order_status === OrderItemStatus.DELIVERING) ||
          (_order.food_order_status === OrderItemStatus.DELIVERED && _order.food_promotion_order_status === OrderItemStatus.DAMAGED_DELIVERING);

        if (isMixedStatus) {
          await handleUpdateOrderStatus(_order.id, {
            order_status: newStatus,
            order_delivered_time: timestamp,
            rider_id: riderId,
          });
        }

        const foodStatusKey = _order.type === 'promotion' ? 'food_promotion_order_status' : 'food_order_status';
        await handleUpdateOrderStatus(_order.id, {
          [foodStatusKey]: newStatus,
          rider_id: riderId,
        });
      } else {
        await handleUpdateOrderStatus(_order.id, {
          order_status: newStatus,
          order_delivered_time: timestamp,
          rider_id: riderId,
        });
      }
      if (_order.has_damaged_order) {
        await updateOrderStatusDamaged(_order.id, DamagedItemStatus.DAMAGED_DELIVERED);
      }

      setTab(TabValue.DELIVERED);
      showToast({
        content: t(LANG_TEXT.DELIVERED_SUCCESS_MESSAGE),
        type: 'success',
      });
    } catch (error) {
      showToast({
        content: t(LANG_TEXT.UPDATE_STATUS_FAIL_MESSAGE),
        type: 'error',
      });
    }
  };

  const tabs = useMemo(() => {
    return [
      {
        value: TabValue.NEW_ORDER,
        label: t(LANG_TEXT.TAB_NEW_ORDER),
        totalItems: newOrders?.length,
      },
      {
        value: TabValue.ONGOING,
        label: t(LANG_TEXT.TAB_ONGOING),
        totalItems: ongoingOrders?.length,
      },
      {
        value: TabValue.DELIVERED,
        label: t(LANG_TEXT.TAB_DELIVERED),
        totalItems: deliveredOrders?.length,
      },
    ];
  }, [deliveredOrders?.length, newOrders?.length, ongoingOrders?.length]);

  return (
    <>
      <Box height="100%">
        <Box padding="16px" bgcolor="#F9FAFB">
          <CustomTabs
            scrollButtons={false}
            variant="scrollable"
            value={tab}
            onChange={(event, newValue) => {
              handleChangeTab(event, newValue);
            }}
          >
            {tabs.map((_tab) => (
              <CustomTab key={_tab.value} label={<TabLabel label={_tab.label} counter={_tab.totalItems} />} value={_tab.value} />
            ))}
          </CustomTabs>
        </Box>
        <Box height="calc(100% - 70px)" overflow="auto" sx={{ scrollBehavior: 'smooth' }}>
          <TabPanel active={tab} value={TabValue.NEW_ORDER}>
            <TabContent
              loading={loading}
              data={newOrders}
              onClickCTA={handlePickup}
              onShowInfo={handleShowInfo}
              emptyStateTitle={t(LANG_TEXT.TAB_NEW_ORDER_EMPTY_STATE)}
              disabled={!isOnline}
            />
          </TabPanel>
          <TabPanel active={tab} value={TabValue.ONGOING}>
            <TabContent
              loading={loading}
              data={ongoingOrders}
              onClickCTA={handleConfirmPickedUp}
              onShowInfo={handleShowInfo}
              emptyStateTitle={t(LANG_TEXT.TAB_ONGOING_EMPTY_STATE)}
              disabled={!isOnline}
            />
          </TabPanel>
          <TabPanel active={tab} value={TabValue.DELIVERED}>
            <TabContent
              loading={loading}
              data={deliveredOrders}
              onClickCTA={handleConfirmDelivered}
              onShowInfo={handleShowInfo}
              emptyStateTitle={t(LANG_TEXT.TAB_DELIVERED_EMPTY_STATE)}
              disabled={!isOnline}
            />
          </TabPanel>
        </Box>
      </Box>
      <DeliveryInfo
        open={showInfo}
        onClose={handleCloseInfo}
        item={selectedOrder}
        onPickUp={handlePickup}
        onConfirmPickedUp={handleConfirmPickedUp}
        onConfirmDelivered={handleConfirmDelivered}
      />
    </>
  );
};

export default Orders;
