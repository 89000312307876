import { Box, Typography } from '@mui/material';
import { Order, OrderItem } from '../../../order.types';
import { useMemo } from 'react';
import { DELIVERY_FEE } from '../../../data';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../../../../../constants/language';

const PaymentSummary: React.FC<{ item: Order }> = ({ item }) => {
  const { t } = useTranslation();

  const { totalPrice, shippingFee, totalPayment } = useMemo(() => {
    let price = 0;
    let total = 0;

    const calculateActualQuantity = (food: OrderItem): number => Math.max(0, (food.item_quantity ?? 0) - (food.item_deleted_quantity ?? 0));

    const calculateTotals = (foods: OrderItem[] = []) => {
      return foods.reduce(
        (acc, curr) => {
          const quantity = calculateActualQuantity(curr);
          acc.totalPrice += quantity * (curr.item_price ?? 0);
          acc.totalQuantity += quantity;
          return acc;
        },
        { totalPrice: 0, totalQuantity: 0 }
      );
    };

    const foodTotals = calculateTotals(item.foods);
    const promoTotals = calculateTotals(item.promotion_foods);

    price = foodTotals.totalPrice + promoTotals.totalPrice;
    total = foodTotals.totalQuantity + promoTotals.totalQuantity;

    const shippingFee = item.type === 'promotion' || total === 0 ? 0 : DELIVERY_FEE;
    const totalPayment = price + shippingFee;

    return { totalPrice: price, shippingFee, totalPayment };
  }, [item.foods, item.promotion_foods, item.type]);

  return (
    <Box bgcolor="white" paddingX="16px">
      <Typography fontSize="14px" lineHeight="20px" color="#667085" fontWeight="500" paddingY="12px">
        {t(LANG_TEXT.ORDER_PAYMENT_SUMMARY)}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingY="8px">
        <Typography fontSize="12px" lineHeight="18px" color="#344054" fontWeight="500">
          {t(LANG_TEXT.ORDER_PRICE)}
        </Typography>
        <Box borderRight="1px solid #EAECF0" height="14px" />
        <Typography fontSize="14px" lineHeight="20px" color="#98A2B3" fontWeight="400">
          ¥ {totalPrice.toLocaleString('ja-JP')}
        </Typography>
      </Box>
      {item.type !== 'promotion' && (
        <Box display="flex" justifyContent="space-between" alignItems="center" paddingY="8px" borderBottom="1px solid #EAECF0">
          <Typography fontSize="12px" lineHeight="18px" color="#344054" fontWeight="500">
            {t(LANG_TEXT.ORDER_DELIVERY_FEE)}
          </Typography>
          <Box borderRight="1px solid #EAECF0" height="14px" />
          <Typography fontSize="14px" lineHeight="20px" color="#98A2B3" fontWeight="400">
            ¥ {shippingFee.toLocaleString('ja-JP')}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingY="12px" borderBottom="1px solid #EAECF0">
        <Typography fontSize="14px" lineHeight="20px" color="#1D2939" fontWeight="600">
          {t(LANG_TEXT.ORDER_TOTAL_PAYMENT)}
        </Typography>
        <Typography fontSize="14px" lineHeight="20px" color="#1D2939" fontWeight="600">
          ¥ {totalPayment.toLocaleString('ja-JP')}
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentSummary;
