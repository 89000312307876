import { Box, Typography } from '@mui/material';
import { Order } from '../../../order.types';
import OrderSummaryItem from './OrderSummaryItem';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../../../../../constants/language';
import { useMemo } from 'react';

const OrderSummary: React.FC<{ item: Order; type: string }> = ({ item, type }) => {
  const { t } = useTranslation();

  const totalQuantity = useMemo(() => {
    const foods = type === 'promotion' ? item?.promotion_foods ?? [] : item?.foods ?? [];

    return foods.reduce((sum, food) => {
      const actualQuantity = food.item_deleted_quantity !== undefined ? food.item_quantity - food.item_deleted_quantity : food.item_quantity;

      return sum + (actualQuantity || 0);
    }, 0);
  }, [item, type]);

  return (
    <Box bgcolor="white">
      <Box display="flex" gap="10px" alignItems="center" padding="12px">
        <Typography fontSize="14px" lineHeight="20px" color="#667085" fontWeight="500">
          {t(LANG_TEXT.ORDER_SUMMARY)}
        </Typography>
        <Box borderRight="1px solid #EAECF0" height="14px" />
        <Typography fontSize="14px" lineHeight="20px" color="#98A2B3" fontWeight="500">
          {totalQuantity} {t(LANG_TEXT.ORDER_ITEMS)}
        </Typography>
      </Box>
      <Box>
        {type !== 'promotion' && item.foods && item.foods.map((_item, index) => <OrderSummaryItem key={`${_item.item_food_id}-${index}`} orderItem={_item} />)}
        {type === 'promotion' &&
          item.promotion_foods &&
          item.promotion_foods.map((_item, index) => <OrderSummaryItem key={`${_item.item_food_id}-${index}`} orderItem={_item} />)}
      </Box>
    </Box>
  );
};

export default OrderSummary;
