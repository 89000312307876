import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import CLOSE_ICON from '../../assets/icons/close-icon.svg';
import { Dialog } from '@mui/material';
import { LANG_TEXT } from '../../constants/language';
import { CustomButton } from '../Button';

interface ModalCustomProps {
  handleSubmit: () => void;
  open: boolean;
  handleClose: () => void;
}

const Modal: React.FC<ModalCustomProps> = ({ handleSubmit, open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={open}
      onClose={(event: React.SyntheticEvent) => {
        handleClose();
        event.stopPropagation();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: '100%',
          background: 'var(--Base-White, #FFF)',
          borderRadius: '12px',
          boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        }}
      >
        <Box
          sx={{
            borderRadius: '12px',
            display: 'flex',
            padding: ' 20px 16px 16px 16px',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: '12px',
            background: 'var(--Base-White, #FFF)',
            alignSelf: 'stretch',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', alignSelf: 'stretch' }}>
            <Typography fontSize={18} fontWeight={600} lineHeight={'28px'} color={'var(--Gray-900, #101828)'}>
              {t(LANG_TEXT.DAMAGED_FOOD_TITLE)}
            </Typography>
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px' }}
            onClick={(e) => {
              handleClose();
              e.stopPropagation();
            }}
          >
            <img src={CLOSE_ICON} height={20} width={20} alt="CLOSE_ICON" />
          </Box>
        </Box>
        <Box sx={{ padding: '0px 16px 8px' }}>
          <Typography fontSize={14} lineHeight={'20px'} color={'var(--Gray-600, #475467)'}>
            {t(LANG_TEXT.DAMAGED_FOOD_REMIND)}
          </Typography>
        </Box>
        <Box sx={{ padding: '0px 16px' }}>
          <Typography fontSize={14} lineHeight={'20px'} color={'var(--Gray-600, #475467)'}>
            {t(LANG_TEXT.DAMAGED_FOOD_DES)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: ' 24px 16px 16px 16px',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '12px',
            flex: '1 0 0',
          }}
        >
          <CustomButton
            variant="outlined"
            padding="0 6px"
            fullWidth
            onClick={(e) => {
              handleClose();
              e.stopPropagation();
            }}
          >
            {t(LANG_TEXT.CANCEL)}
          </CustomButton>
          <CustomButton
            variant="contained"
            padding="0 6px"
            fullWidth
            onClick={async (e) => {
              handleSubmit();
              handleClose();
              e.stopPropagation();
            }}
          >
            {t(LANG_TEXT.SUBMIT)}
          </CustomButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default Modal;
