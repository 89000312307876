const LANG_DISPLAY_KEY = 'lang_display';
const LANG_CLIENT_KEY = 'client_lang_display';
const LANGUAGES = {
  JAPAN: 'ja',
  ENGLISH: 'en',
};

const SITES = {
  CMS: 'cms',
  CLIENT: 'client',
  RIDER: 'rider',
};

enum LANG_TEXT {
  LOGIN_BUTTON = 'LOGIN_BUTTON',
  LOGIN_EMAIL = 'LOGIN_EMAIL',
  LOGIN_EMAIL_PLACEHOLDER = 'LOGIN_EMAIL_PLACEHOLDER',
  LOGIN_REQUIRED_EMAIL = 'LOGIN_REQUIRED_EMAIL',
  LOGIN_INVALID_EMAIL = 'LOGIN_INVALID_EMAIL',
  LOGIN_PASSWORD = 'LOGIN_PASSWORD',
  LOGIN_PASSWORD_PLACEHOLDER = 'LOGIN_PASSWORD_PLACEHOLDER',
  LOGIN_REQUIRED_PASSWORD = 'LOGIN_REQUIRED_PASSWORD',
  LOGIN_REMEMBER_ME = 'LOGIN_REMEMBER_ME',
  LOGIN_SUCCESS_MESSAGE = 'LOGIN_SUCCESS_MESSAGE',
  LOGIN_FAIL_MESSAGE = 'LOGIN_FAIL_MESSAGE',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_MESSAGE = 'FORGOT_PASSWORD_MESSAGE',
  FORGOT_PASSWORD_MODAL_TITLE = 'FORGOT_PASSWORD_MODAL_TITLE',
  FORGOT_PASSWORD_MODAL_HINT = 'FORGOT_PASSWORD_MODAL_HINT',
  FORGOT_PASSWORD_MODAL_BUTTON = 'FORGOT_PASSWORD_MODAL_BUTTON',
  SELECT_LANGUAGE = 'SELECT_LANGUAGE',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  LOGOUT = 'LOGOUT',
  NOTIFICATION_EMPTY_STATE = 'NOTIFICATION_EMPTY_STATE',
  NOTIFICATION_NEW_ORDER_MESSAGE = 'NOTIFICATION_NEW_ORDER_MESSAGE',
  NOTIFICATION_READY_ORDER_MESSAGE = 'NOTIFICATION_READY_ORDER_MESSAGE',
  TAB_NEW_ORDER = 'TAB_NEW_ORDER',
  TAB_NEW_ORDER_EMPTY_STATE = 'TAB_NEW_ORDER_EMPTY_STATE',
  TAB_ONGOING = 'TAB_ONGOING',
  TAB_ONGOING_EMPTY_STATE = 'TAB_ONGOING_EMPTY_STATE',
  TAB_DELIVERED = 'TAB_DELIVERED',
  TAB_DELIVERED_EMPTY_STATE = 'TAB_DELIVERED_EMPTY_STATE',
  ORDER_DELIVERY_INFORMATION = 'ORDER_DELIVERY_INFORMATION',
  ORDER_ID = 'ORDER_ID',
  ORDER_ITEM = 'ORDER_ITEM',
  ORDER_ITEMS = 'ORDER_ITEMS',
  ORDER_SEAT_NUMBER = 'ORDER_SEAT_NUMBER',
  ORDER_SUMMARY = 'ORDER_SUMMARY',
  ORDER_PAYMENT_SUMMARY = 'ORDER_PAYMENT_SUMMARY',
  ORDER_PRICE = 'ORDER_PRICE',
  ORDER_DELIVERY_FEE = 'ORDER_DELIVERY_FEE',
  ORDER_TOTAL_PAYMENT = 'ORDER_TOTAL_PAYMENT',
  ORDER_PAYMENT_METHOD = 'ORDER_PAYMENT_METHOD',
  ORDER_DISABLE_TOOLTIP = 'ORDER_DISABLE_TOOLTIP',
  ORDER_DELIVERED_AT = 'ORDER_DELIVERED_AT',
  NEW = 'NEW',
  PREPARING = 'PREPARING',
  DELIVERING = 'DELIVERING',
  READY_FOR_PICK_UP = 'READY_FOR_PICK_UP',
  DELIVERED = 'DELIVERED',
  PICK_UP_ORDER = 'PICK_UP_ORDER',
  PICK_UP_ORDER_SUCCESS_MESSAGE = 'PICK_UP_ORDER_SUCCESS_MESSAGE',
  PICK_UP_ORDER_FAIL_MESSAGE = 'PICK_UP_ORDER_FAIL_MESSAGE',
  PICKED_UP_FOOD = 'PICKED_UP_FOOD',
  PICKED_UP_FOOD_SUCCESS_MESSAGE = 'PICKED_UP_FOOD_SUCCESS_MESSAGE',
  PICKED_UP_ORDER_FAIL_MESSAGE = 'PICKED_UP_ORDER_FAIL_MESSAGE',
  SLIDE_TO_MARK_AS_DONE = 'SLIDE_TO_MARK_AS_DONE',
  DELIVERED_SUCCESS_MESSAGE = 'DELIVERED_SUCCESS_MESSAGE',
  UPDATE_STATUS_FAIL_MESSAGE = 'UPDATE_STATUS_FAIL_MESSAGE',
  CREDIT_CARD = 'CREDIT_CARD',
  MOBILE_PAYMENT = 'MOBILE_PAYMENT',
  ZONE = 'ZONE',
  DAMAGED_FOOD = 'DAMAGED_FOOD',
  DAMAGED_FOOD_TITLE = 'DAMAGED_FOOD_TITLE',
  DAMAGED_FOOD_DES = 'DAMAGED_FOOD_DES',
  DAMAGED_FOOD_REMIND = 'DAMAGED_FOOD_REMIND',
  CANCEL = 'CANCEL',
  SUBMIT = 'SUBMIT',
  QUANTITY = 'QUANTITY',
  DAMAGED = 'DAMAGED',
}

export { SITES, LANGUAGES, LANG_DISPLAY_KEY, LANG_CLIENT_KEY, LANG_TEXT };
