import { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import DongSoundFile from '../assets/wav/swiftly-610.wav';
import { db, messaging } from '../firebase/config';
import DB_TABLE, { VAPID_KEY_PROD, VAPID_KEY_STAG } from '../firebase/db-config';
import Toast from '../components/Toast';
import { useAuthContext } from './authContext';
import { sendToTopic, subscribeTopic, unsubscribeFromTopic } from '../api/message-api';
import { DamagedItemStatus, OrderItemStatus } from '../views/Orders/order.types';
import { useTranslation } from 'react-i18next';
import { LANG_TEXT } from '../constants/language';

export enum TopicName {
  RIDER = 'RIDER',
  MANAGE = 'MANAGE',
}

interface NotificationContextValue {
  deviceToken: string;
  notifications: any[];
  isNotificationEnabled: boolean;
  pushNotification: (payload: any) => void;
  markAsRead: () => void;
  showToast: (content: ToastContent) => void;
  sendMessage: (payload: { status: string; id: string }, topic: string) => void;
}

export type Message = { message: string; isUnread: boolean; id: string };
export type ToastContent = { type: 'error' | 'success' | 'info'; content: string; duration?: number };

const NotificationContext = createContext<NotificationContextValue>({
  deviceToken: '',
  notifications: [],
  isNotificationEnabled: false,
  pushNotification: (payload) => {},
  markAsRead: () => {},
  showToast: () => {},
  sendMessage: () => {},
});

const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const { user, isOnline } = useAuthContext();
  const { t } = useTranslation();

  const toast = useRef<ToastContent>({
    type: 'success',
    content: '',
  });

  const [notifications, setNotifications] = useState<Message[]>([]);
  const [deviceToken, setDeviceToken] = useState<string>('');
  const [openToast, setOpenToast] = useState(false);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);

  const showToast = (content: ToastContent) => {
    toast.current = content;
    setOpenToast(true);
  };

  const handlePlaySound = async () => {
    try {
      const audio = document.getElementById('audio-service-request-sound');
      if (audio instanceof HTMLAudioElement && audio !== null) {
        await audio.play();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const pushNotification = (payload: any) => {
    const notificationMessages: Record<string, string> = {
      [OrderItemStatus.READY_FOR_PICK_UP]: LANG_TEXT.NOTIFICATION_READY_ORDER_MESSAGE,
      [OrderItemStatus.PREPARING]: LANG_TEXT.NOTIFICATION_NEW_ORDER_MESSAGE,
      [DamagedItemStatus.DAMAGED_READY_FOR_PICK_UP]: LANG_TEXT.NOTIFICATION_READY_ORDER_MESSAGE,
      [DamagedItemStatus.DAMAGED_PREPARING]: LANG_TEXT.NOTIFICATION_NEW_ORDER_MESSAGE,
    };

    const message = payload?.data?.status ? t(notificationMessages[payload.data.status], { id: payload.data.id }) : '';

    setNotifications((prev) => [{ message, isUnread: true, id: payload?.data?.id }, ...prev]);
    handlePlaySound();
    showToast({
      content: message,
      type: 'success',
      duration: 3000,
    });
  };

  const sendMessage = (
    message: {
      status: string;
      id: string;
    },
    topic: string
  ) => {
    sendToTopic(topic, message);
  };

  const markAsRead = () => {
    setNotifications((prev) => prev.map((i) => ({ ...i, isUnread: false })));
  };

  const getMessagingToken = async () => {
    const permission = await Notification.requestPermission();

    if (permission === 'granted' && user?.uid) {
      const userDoc = await db.collection(DB_TABLE.DB_TABLE_USERS).doc(user.uid).get();
      const deviceTokens = userDoc.data()?.device_tokens || [];
      const vapid_key = process.env.REACT_APP_ENV === 'staging' ? VAPID_KEY_STAG : VAPID_KEY_PROD;

      let token = deviceToken;

      if (!token || token === '') {
        token = await messaging.getToken({ vapidKey: vapid_key });
      }

      setDeviceToken(token);
      // Save token to User table
      if (!deviceTokens?.includes(token) && user?.uid && isOnline) {
        db.collection(DB_TABLE.DB_TABLE_USERS)
          .doc(user.uid)
          .update({ device_tokens: [...deviceTokens, token] });
      }

      setIsNotificationEnabled(true);
    } else if (permission === 'denied') {
      setIsNotificationEnabled(false);
    }
  };

  useEffect(() => {
    if (user?.uid) getMessagingToken();
  }, [user?.uid]);

  useEffect(() => {
    messaging.onMessage((payload) => {
      pushNotification(payload);
    });
  }, []);

  return (
    <NotificationContext.Provider value={{ deviceToken, notifications, isNotificationEnabled, showToast, pushNotification, markAsRead, sendMessage }}>
      <audio id="audio-service-request-sound">
        <source src={DongSoundFile} type="audio/wav" />
      </audio>
      {children}
      <Toast
        content={toast.current?.content}
        variant={toast.current?.type}
        open={openToast}
        onClose={() => {
          setOpenToast(false);
        }}
      />
    </NotificationContext.Provider>
  );
};

const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  return context;
};

export { NotificationProvider, useNotificationContext };
